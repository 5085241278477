@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.monthly-body {
  @apply font-th border-[4px] border-solid border-[#ededef];
}

.monthly-body > div {
  @apply border-none;
}

.monthly-body > div > div > div {
  @apply relative border-r border-b text-xl font-bold border-[#ededef] z-1;
}
.monthly-body > div > div > div > div {
  @apply relative -m-2 p-2 lg:justify-center z-1;
}

.monthly-body > div > div > div > ul .day-event {
  @apply absolute inset-0 bg-brown-400 pt-9 overflow-y-auto;
}

.monthly-body > div > div > div > ul .day-event::-webkit-scrollbar {
  @apply w-1;
}

.monthly-body > div > div > div > ul .day-event::-webkit-scrollbar-thumb {
  @apply bg-brown-900;
}

div[aria-label='Day of Week'] {
  @apply text-center;
}

.monthly-nav {
  @apply flex justify-between items-center font-th font-bold mb-3;
}

.monthly-nav > div {
  @apply mb-0 justify-start;
}

.monthly-nav > div > div {
  @apply text-3xl whitespace-nowrap w-auto;
}

.monthly-nav > div button {
  @apply before:text-3xl;
  font-family: 'Material Icons';
  font-size: 0;
}

.monthly-nav > div button:first-of-type::before {
  content: 'chevron_left';
}

.monthly-nav > div button:last-of-type::before {
  content: 'chevron_right';
}

@media screen and (max-width: 475px) {
  .monthly-nav {
    @apply flex-col;
  }

  .monthly-nav > button {
    @apply mt-3;
  }
}
