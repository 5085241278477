/* @import url("https://fonts.googleapis.com/css2?family=Krub:wght@400;500;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Thasadith:wght@700&display=swap');

@font-face {
  font-family: 'TH Krub';
  src: local('TH Krub'), url(./assets/fonts/TH-Krub-Bold.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'TH Krub Reg';
  src: local('TH Krub Reg'), url(./assets/fonts/TH-Krub.ttf) format('truetype');
  font-weight: 400;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  color: theme('colors.brown.900');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: theme('fontFamily.default'), serif, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.admin * {
  font-family: theme('fontFamily.th');
}

/* @media screen and (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: theme('colors.white');
  }
  ::-webkit-scrollbar-thumb {
    background-color: theme('colors.brown.600');
  }
  ::-webkit-scrollbar-thumb:hover {
    background: theme('colors.brown.900');
  }
} */
