.timePicker-popup {
  @apply fixed z-[2000];
}

.timePicker-popup .rc-time-picker-panel-inner {
  @apply mt-[50px];
}

.timePicker-popup .rc-time-picker-panel-input-wrap {
  @apply hidden;
}

.timePicker-popup .rc-time-picker-panel-select::-webkit-scrollbar {
  @apply w-[3px];
}

.timePicker-popup .rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
}

.timePicker-popup .rc-time-picker-panel-select ul li {
  @apply text-base font-th;
}

.timePicker-popup .rc-time-picker-panel-select ul li:hover, .timePicker-popup .rc-time-picker-panel-select ul li.rc-time-picker-panel-select-option-selected {
  @apply bg-brown-100;
}

@media screen and (max-width: 599.95px) {
  .timePicker-popup .rc-time-picker-panel-inner {
    @apply mt-[45px];
  }
}
